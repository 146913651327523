import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
//import { PageContents } from 'app/core/components/Page/PageContents';

// import { InstallAgent } from './InstallAgent';
// import { InstallAgentSNMP } from './InstallAgentSNMP';
import { InstallationGuideSiem } from './InstallationGuideSiem';
import { NavigationButtons } from './NavigationButtons';
import { OperatingSystem } from './OperatingSystem';
import { ProgressBar } from './ProgressBar';
import { TenantDetail } from './types';

const TOTAL_STEPS = 7;

export const WelcomeBanner: FC = () => {
  const [tenantDetail, setTenantDetail] = useState<TenantDetail>();
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState<number>(1); // Ensure it starts at step 1
  const [selectedPlatform, setSelectedPlatform] = useState<string | undefined>(undefined);
  const [osVersion, setOsVersion] = useState<string>('');
  const [agentName, setAgentName] = useState<string>('');
  // console.log(selectedPlatform === null);

  useEffect(() => {
    async function fetchTenantDetail() {
      try {
        const response = await getBackendSrv().get('/api/org/detail');
        setTenantDetail(response);
      } catch (err) {
        setError('Failed to fetch tenant details.');
      }
    }

    if (!tenantDetail) {
      fetchTenantDetail();
    }
  }, [tenantDetail]);

  const handleNext = () => {
    if (step === 1) {
      console.log('Selected platform:', selectedPlatform);
      console.log('check AgentName :', agentName);
    }
    if (step < TOTAL_STEPS) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    let stepCheck = step;
    if (step > 1) {
      setStep(step - 1);
      stepCheck = stepCheck - 1;
    }
    if (stepCheck === 1) {
      setSelectedPlatform(undefined);
      console.log('Check step setSelectedPlatform ', selectedPlatform);
    } else if (stepCheck === 2) {
      setOsVersion('');
      console.log('Check step setOsVersion ', osVersion);
    } else if (stepCheck === 3) {
      setAgentName('');
      console.log('Check step setAgentName ', agentName);
    }
  };

  const handlePlatformSelect = (platform: string) => {
    setSelectedPlatform(platform);
    setOsVersion('');
    setAgentName('');
    // console.log('Selected platform:', platform);
  };

  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      {step === 1 && (
        <div style={{ textAlign: 'center' }}>
          <h4>To get started using the Agent, select your platform</h4>
        </div>
      )}
      {step === 2 && (
        <div style={{ textAlign: 'center' }}>
          <h4>To get started using the Agent, select your OS version</h4>
        </div>
      )}
      {step === 3 && (
        <div style={{ textAlign: 'center' }}>
          <h4>To get started using the Agent, assign a name to the agent</h4>
        </div>
      )}
      {step === 4 && (
        <div style={{ textAlign: 'center' }}>
          <h4>Install Agent for SIEM</h4>
          <p>
            For {selectedPlatform} -- {osVersion}
          </p>
        </div>
      )}
      {step === 5 && (
        <div style={{ textAlign: 'center' }}>
          <h4>Installing agent tutorial</h4>
          <p>For {selectedPlatform}</p>
        </div>
      )}
      {step === 6 && (
        <div style={{ textAlign: 'center' }}>
          <h4>Tutorial on using Security Incident</h4>
        </div>
      )}
      {step === 7 && (
        <div style={{ textAlign: 'center' }}>
          <h4>Tutorial on using Workflow</h4>
        </div>
      )}
      <ProgressBar step={step} totalSteps={TOTAL_STEPS} error={!!error} />
      {step === 1 && <OperatingSystem onPlatformSelect={handlePlatformSelect} />}{' '}
      {/* Display OperatingSystem component on step 1 */}
      {step !== 1 && (
        <InstallationGuideSiem
          tenantDetail={tenantDetail}
          hasLoading={!tenantDetail}
          selectedPlatform={selectedPlatform}
          step={step}
          osVersion={osVersion}
          setOsVersion={setOsVersion}
          agentName={agentName}
          setAgentName={setAgentName}
        />
      )}{' '}
      {step === 5 && (
        <div className={styles.videoContainer} style={{ flexDirection: 'column' }}>
          {selectedPlatform === 'windows' ? (
            <>
              <video controls className={styles.videoPlayer}>
                <source src="public/img/install_agent_guide.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          ) : selectedPlatform === 'linux' ? (
            <>
              <video controls className={styles.videoPlayer}>
                <source src="public/img/install_agent_guide_linux_2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          ) : (
            <></>
          )}

          {/* <p>{''}</p>
          <PageContents>
            <h3>{'Step 1: Select the Operating System and Version'}</h3>
            <h5>{'Choose your desired operating system (OS) and the appropriate version that fits your environment.'}</h5>
            <p>{''}</p>

            <h3>{'Step 2: Install the Agent'}</h3>
            <h5>{'Follow the installation instructions specific to the OS and version you\n\'ve selected. Ensure that the agent is successfully installed.'}</h5>
            <p>{''}</p>

            <h3>{'Step 3: Wait for 5-10 Minutes'}</h3>
            <h5>{'After the agent installation is complete, wait for approximately 5-10 minutes. This time allows the agent to register and start communicating with the server.'}</h5>
            <p>{''}</p>
            
            <h3>{'Step 4: Check Agent Status'}</h3>
            <h5>{'After waiting, go to the Agent Overview Dashboard to verify the status of the agent. Ensure that the agent is correctly listed and functioning as expected.'}</h5>
            <p>{''}</p>
          </PageContents> */}
        </div>
      )}
      {step === 6 && (
        <div className={styles.videoContainer}>
          <video controls className={styles.videoPlayer}>
            <source src="public/img/SSH.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {step === 7 && (
        <div className={styles.videoContainer}>
          <video controls className={styles.videoPlayer}>
            <source src="public/img/Testworkflows.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {/* Display OperatingSystem component on step 1 */}
      <NavigationButtons
        onNext={handleNext}
        onBack={handleBack}
        disableNext={step >= TOTAL_STEPS}
        disableBack={step <= 1}
        CheckPlatform={selectedPlatform === undefined}
        CheckAgentName={agentName === ''}
        CheckOsVersion={osVersion === ''}
        step={step}
      />
      {/* {error && step === TOTAL_STEPS && <div className={styles.error}>{error}</div>} */}
    </div>
  );
};

// WelcomeBanner.displayName = 'WelcomeBanner';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      background-size: cover;
      height: 500px;
      padding: 40px ${theme.spacing(6)};
    `,
    error: css`
      color: red;
      font-size: ${theme.typography.h3.fontSize};
      padding: ${theme.spacing(4)};
    `,
    videoContainer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: ${theme.spacing(4)};
    `,
    videoPlayer: css`
      max-width: 70%;
      height: auto;
    `,
  };
};
