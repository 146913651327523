import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2 } from '@grafana/ui'; /// styleMixins

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/logo_SOAR.svg" alt="Grafana" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(public/img/BG-Login-SOAR.jpg) no-repeat left center;
      background-size: contain;
      opacity: 1;
      transition: opacity 3s ease-in-out;
    }

    @media (max-width: 1440px) {
      &:before {
        background-position: center center;
        background-size: cover;
        opacity: 1;
      }
    }
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/logo_SOAR.svg" alt="Grafana" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css`
    // background: ${colorManipulator.alpha(theme.colors.background.primary, 0.7)};
    background: ${colorManipulator.alpha('#FFFFFF', 1)};
    background-size: cover;
    color: #000000;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'SOAR';
  static LoginTitle = '';
  static GetLoginSubTitle = (): null | string => {
    return null;
  };
}
