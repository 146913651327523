import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutModes } from '@grafana/data';
import { AwsUsersState } from 'app/types';

export const initialAwsUsersState: AwsUsersState = {
  awsUsers: [],
  searchQuery: '',
  searchPage: 1,
  hasFetched: false,
  layoutMode: LayoutModes.List,
};

const awsUsersSlice = createSlice({
  name: 'awsUsers',
  initialState: initialAwsUsersState,
  reducers: {
    awsUsersLoaded: (state, action): AwsUsersState => {
      console.log(action.payload);

      return { ...state, hasFetched: true, awsUsers: action.payload };
    },
    setSearchQuery: (state, action): AwsUsersState => {
      return { ...state, searchQuery: action.payload };
    },
    setAwsUsersLayoutMode: (state, action): AwsUsersState => {
      return { ...state, layoutMode: action.payload };
    },
    setAwsUsersSearchPage: (state, action: PayloadAction<number>): AwsUsersState => {
      console.log('setNodesSearchPage');
      return { ...state, searchPage: action.payload };
    },
  },
});

export const { awsUsersLoaded, setSearchQuery, setAwsUsersSearchPage, setAwsUsersLayoutMode } = awsUsersSlice.actions;

export const awsUsersReducer = awsUsersSlice.reducer;

export default {
  awsUsers: awsUsersReducer,
};
