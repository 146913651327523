import { css } from '@emotion/css';
import React, { FC, useState } from 'react';

const buttonStyles = css`
  position: relative;
  width: 400px;
  height: 200px;
  margin: 10px;
  padding: 10px;
  background-color: #dedede;
  color: white;
  border: 5px;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const pressedStyles = css`
  border: 2px solid #0085ff;
  background-color: #dedede;
`;

interface OperatingSystemProps {
  onPlatformSelect: (platform: string) => void;
}

export const OperatingSystem: FC<OperatingSystemProps> = ({ onPlatformSelect }) => {
  const [pressedButton, setPressedButton] = useState<string | null>(null);

  const handleButtonClick = (buttonId: string) => {
    setPressedButton(buttonId);
    onPlatformSelect(buttonId); // Pass selected platform back to parent component
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <button
        onClick={() => {
          handleButtonClick('windows');
        }}
        className={`${buttonStyles} ${pressedButton === 'windows' ? pressedStyles : ''}`}
      >
        <img
          src="public/img/Microsoft_Windows-Logo.wine.svg"
          alt="Button Image"
          className={css`
            width: 100%;
            height: 100%;
          `}
        />
        {/* {pressedButton === 'first' && (
          <img
            src="check-mark.png"
            alt="Check Mark"
            className={css`position: absolute; top: 0; left: 0; width: 100%; height: 100%;`}
          />
        )} */}
      </button>

      <button
        onClick={() => {
          handleButtonClick('linux');
        }}
        className={`${buttonStyles} ${pressedButton === 'linux' ? pressedStyles : ''}`}
      >
        <img
          src="public/img/linux-logo.svg"
          alt="Button Image"
          className={css`
            width: 100%;
            height: 100%;
          `}
        />
        {/* {pressedButton === 'second' && (
          <img
            src="check-mark.png"
            alt="Check Mark"
            className={css`position: absolute; top: 0; left: 0; width: 100%; height: 100%;`}
          />
        )} */}
      </button>
    </div>
  );
};
