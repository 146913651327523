// Libraries
import { css } from '@emotion/css';
import React, { FC } from 'react';

// Components
import { GrafanaTheme2 } from '@grafana/data';
import { HorizontalGroup, LinkButton, useStyles2 } from '@grafana/ui';
import { Branding } from 'app/core/components/Branding/Branding';
import config from 'app/core/config';

import { ChangePassword } from '../ForgottenPassword/ChangePassword';

import LoginCtrl from './LoginCtrl';
import { LoginForm } from './LoginForm';
import { LoginLayout, InnerBox } from './LoginLayout';
import { LoginServiceButtons } from './LoginServiceButtons';
// import { RegisterForm } from './RegisterForm';
import { UserSignup } from './UserSignup';

const forgottenPasswordStyles = css`
  padding: 0;
  margin-top: 4px;
`;

export const LoginPage: FC = () => {
  document.title = Branding.AppTitle;
  // const [registerFormOpen, setRegisterFormOpen] = useState(false);
  const styles = useStyles2(getStyles);

  return (
    <LoginLayout>
      <LoginCtrl>
        {({
          loginHint,
          passwordHint,
          disableLoginForm,
          disableUserSignUp,
          login,
          isLoggingIn,
          changePassword,
          skipPasswordChange,
          isChangingPassword,
        }) => (
          <>
            {!isChangingPassword && (
              <div className={styles.default_font}>
                <InnerBox>
                  {!disableLoginForm && (
                    <LoginForm
                      onSubmit={login}
                      loginHint={loginHint}
                      passwordHint={passwordHint}
                      isLoggingIn={isLoggingIn}
                    >
                      <HorizontalGroup justify="flex-end">
                        <LinkButton
                          className={forgottenPasswordStyles}
                          fill="text"
                          href={`${config.appSubUrl}/user/password/send-reset-email`}
                        >
                          Forgot your password?
                        </LinkButton>
                      </HorizontalGroup>
                    </LoginForm>
                  )}
                  <>
                    <LoginServiceButtons />
                  </>
                  {/* {!registerFormOpen && (
                    <>
                      <LoginServiceButtons />
                      <div className={styles.text_with_line}>
                        <span>หรือ</span>
                      </div>
                      <Button onClick={() => setRegisterFormOpen(true)} className={styles.button_signup}>
                        สมัครสมาชิกผ่านอีเมล
                      </Button>
                      <div className={styles.text_grey}>*สมัครสมาชิก เพื่อทดลองใช้งานฟรี 7 วัน</div>
                    </>
                  )}

                  {registerFormOpen && (
                    <>
                      <div
                        style={{ marginRight: 'auto' }}
                        className={styles.go_back_button}
                        onClick={() => setRegisterFormOpen(false)}
                      >
                        <span className={styles.go_back_button}>{'< '} ย้อนกลับ</span>
                      </div>
                      <RegisterForm />
                    </>
                  )} */}
                  {!disableUserSignUp && <UserSignup />}
                </InnerBox>
              </div>
            )}
            {isChangingPassword && (
              <InnerBox>
                <ChangePassword onSubmit={changePassword} onSkip={() => skipPasswordChange()} />
              </InnerBox>
            )}
          </>
        )}
      </LoginCtrl>
    </LoginLayout>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    text_grey: css`
      color: #cccccc;
    `,
    text_with_line: css`
      width: 100%;
      text-align: center;
      border-bottom: 1.5px dashed #cccccc;
      line-height: 0.1em;
      margin: 30px 0 30px;
      span {
        background: #fff;
        color: #cccccc;
        padding: 0 10px;
      }
    `,
    button_signup: css`
      // margin-top: 30px;
      padding: 24px 110px;
      justify-content: center;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(180deg, #339dff 0%, #1a91ff 52%, #0085FF 100%) border-box;
      color: #0085FF;
      border: 1px solid transparent;
      border-radius: 46px;
      &:hover {
        background: linear-gradient(180deg, #339dff 0%, #1a91ff 52%, #0085FF 100%);
        border: none;
      }
    `,
    go_back_button: css`
      background: linear-gradient(180deg, #339dff 0%, #1a91ff 52%, #0085FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    `,
    default_font: css`
      font-family: 'Kanit' !important;
      font-style: normal;
      button,
      input,
      div,
      span {
        font-family: 'Kanit' !important;
      }
    `,
  };
};
