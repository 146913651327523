import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutModes } from '@grafana/data';
import { SecurityIncident , SecurityIncidentsState  , SecurityIncidentListUpdate} from 'app/types';

export const initialState: SecurityIncidentsState = {
  incidents: [],
  newIncident: {
    name: '',
    owner: 0,
    assign_to: 0,
    occurred: new Date(),
    reminder: new Date(),
    role: '',
    type: '',
    severity: '',
    playbook: 0,
    phase: '',
    detail: '',
    created_at: new Date(),
    updated_at: new Date(),
  },
  user: {
    id: 0,
    org_id: 0,
    login: '',
    role: '',
  },
  userLists: [],
  newIncidentListCreate: {
    name: '',
    owner: 0,
    assign_to: 0,
    occurred: new Date(),
    reminder: new Date(),
    role: '',
    type: '',
    severity: '',
    playbook: 0,
    phase: '',
    detail: '',
    created_at: new Date(),
    updated_at: new Date(),
    files: [],
  },
  newIncidentListUpdate: {
    name: '',
    owner: 0,
    assign_to: 0,
    occurred: '',
    reminder: '',
    role: '',
    type: '',
    severity: '',
    playbook: 0,
    phase: '',
    detail: '',
    created_at: new Date(),
    updated_at: new Date(),
    files: [],
  },
  removeIncident: {
    id: 0
  },
  searchQuery: '',
  searchPage: 1,
  selectedIncident: null,
  hasFetched: false,
  layoutMode: LayoutModes.List,
  userRole: '',
  feedbackLoopData: [],
  timeFilter: {
    second: 0,
    minute: 0,
    hour: 24,  // default option
    day: 0,
    month: 0,
    year: 0,
    range: false,
    start:  new Date(),
    end:  new Date()
  },
  numData: [],
  tableData: [],
  chartData: [],
};

const securityIncidentsSlice = createSlice({
  name: 'securityIncidents',
  initialState : initialState,
  reducers: {
    securityIncidentsLoaded: (state, action): SecurityIncidentsState => {
      // console.log("test securityIncidentsLoaded check : ",action.payload);
      return { ...state, hasFetched: true, incidents: action.payload };
    },
    securityIncidentCreated: (state, action: PayloadAction<SecurityIncident>): SecurityIncidentsState => {
      return { ...state, incidents: [...state.incidents, action.payload] };
    },
    securityIncidentUpdated: (state, action: PayloadAction<SecurityIncident>): SecurityIncidentsState => {
      return {
        ...state,
        incidents: state.incidents.map((incident) => (incident.id === action.payload.id ? action.payload : incident)),
      };
    },
    securityIncidentsLoadbyid: (state, action): SecurityIncidentsState => {
      // console.log("test securityIncidentsLoadbyid check : ",action.payload);
      return { ...state, newIncidentListUpdate: action.payload };
    },
    usersLoadbyOrgId: (state, action): SecurityIncidentsState => {
      // console.log("test securityIncidentsLoadbyid check : ",action.payload);
      return { ...state, userLists: action.payload };
    },
    userRole:(state, action): SecurityIncidentsState  => {
      // console.log("test securityIncidentsLoadbyid check : ",action.payload);
      return { ...state, userRole: action.payload };
    },
    feedbackLoopData:(state, action): SecurityIncidentsState  => {
      // console.log("test feedbackLoopData check : ",action.payload);
      return { ...state, feedbackLoopData: action.payload };
    },
    setSecurityIncidentListCreate: (state, action: PayloadAction<SecurityIncidentListUpdate>): SecurityIncidentsState => {
      return {
        ...state,
        newIncidentListUpdate: {
          ...state.newIncidentListCreate,
          ...action.payload,
        },
      };
    },
    // securityIncidentDeleted: (state, action: PayloadAction<number>): SecurityIncidentsState => {
    //   return {
    //     ...state,
    //     incidents: state.incidents.filter((incident) => incident.id !== action.payload),
    //   };
    // },
    // setNewIncident: (state, action: PayloadAction<SecurityIncident>): SecurityIncidentsState => {
    //   return { ...state, newIncident: action.payload };
    // },
    // setSelectedIncident: (state, action: PayloadAction<SecurityIncident | null>): SecurityIncidentsState => {
    //   return { ...state, selectedIncident: action.payload };
    // },
    setSearchQuery: (state, action): SecurityIncidentsState => {
      return { ...state, searchQuery: action.payload };
    },    
    setSecurityIncidentsLayoutMode: (state, action): SecurityIncidentsState => {
      // console.log("test check : ",action.payload);
      return { ...state, layoutMode: action.payload };
    },
    setSecurityIncidentsSearchPage: (state, action: PayloadAction<number>): SecurityIncidentsState => {
      // console.log("test check : ",action.payload);
      return { ...state, searchPage: action.payload };
    },

    setSecurityIncidentsTimeFilter : (state, action): SecurityIncidentsState => {
      return { ...state, timeFilter: action.payload };
    },

    setSecurityIncidentsDashboardNumData : (state, action): SecurityIncidentsState => {
      return { ...state, numData: action.payload };
    },

    setSecurityIncidentsDashboardTableData : (state, action): SecurityIncidentsState => {
      return { ...state, tableData: action.payload };
    },

    setSecurityIncidentsDashboardChartData : (state, action): SecurityIncidentsState => {
      return { ...state, chartData: action.payload };
    },
    resetState: () => initialState,
  },
});

export const {
  securityIncidentsLoaded,
  setSearchQuery,
  setSecurityIncidentsLayoutMode,
  setSecurityIncidentsSearchPage,
  securityIncidentCreated,
  securityIncidentUpdated,
  securityIncidentsLoadbyid,
  usersLoadbyOrgId,
  userRole,
  feedbackLoopData,
  // securityIncidentDeleted,
  // setNewIncident,
  // setSelectedIncident,
  setSecurityIncidentsTimeFilter,
  setSecurityIncidentsDashboardNumData,
  setSecurityIncidentsDashboardTableData,
  setSecurityIncidentsDashboardChartData,
  setSecurityIncidentListCreate,
  resetState,
} = securityIncidentsSlice.actions;

export const securityIncidentsReducer = securityIncidentsSlice.reducer;

export default {
  securityIncidents: securityIncidentsReducer,
};


