import { SafeDynamicImport } from 'app/core/components/DynamicImports/SafeDynamicImport';
import config from 'app/core/config';
import { RouteDescriptor } from 'app/core/navigation/types';

const workflowRoutes = [
  {
    path: '/workflow', //path
    roles: () => (config.editorsCanAdmin ? [] : ['Admin']),
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "dashboardWorkflow" */ 'app/features/workflow/WorkflowList')
    ),
  },
  {
    path: '/workflow/create', //path
    roles: () => (config.editorsCanAdmin ? [] : ['Admin']),
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "managePageWorkflow" */ 'app/features/workflow/CreateWorkflow')
    ),
  },
  {
    path: '/workflow/edit/:id', //path
    roles: () => (config.editorsCanAdmin ? [] : ['Admin']),
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "managePageWorkflowEdit" */ 'app/features/workflow/EditWorkflow')
    ),
  },
];

export function getWorkflowRoutes(): RouteDescriptor[] {
  return workflowRoutes;
}
