import { css } from '@emotion/css';
import React, { FC } from 'react';

interface ProgressBarProps {
  step: number;
  totalSteps: number;
  error?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = ({ step, totalSteps, error }) => {
  const barStyles = (isActive: boolean) => css`
    flex: 1;
    height: 8px;
    background-color: ${isActive ? (error ? '#0085ff' : '#1d72f3') : '#e0e0e0'};
    margin: 0 2px;
    border-radius: 4px;
  `;

  const containerStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  `;

  const stepTextStyle = css`
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  `;

  return (
    <div>
      <div className={stepTextStyle}>
        {step} of {totalSteps}
      </div>
      <div className={containerStyles}>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <div key={index} className={barStyles(index + 1 === step)} />
        ))}
      </div>
    </div>
  );
};
