import { css, cx } from '@emotion/css';
import React, { FC, useState, ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Button, IconButton, useTheme2, Input, LoadingPlaceholder, Select } from '@grafana/ui';
import { useGlobalState } from "app/store/GlobalStateProvider";

import SOARlogo from '../../../../img/logo_SOAR.svg';

interface Message {
  message: string;
  role: string;
  status: number;
}

export const ChatsBot: FC = React.memo(() => {
  const theme = useTheme2();

  const { state } = useGlobalState();
  useEffect(() => {
    if(state.global_chatbot_message !== undefined && state.global_chatbot_message !== "") {autoAsk(state.global_chatbot_message!);}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.global_chatbot_message]);
  // const [ReloadLatestMessage, setReloadLatestMessage] = useState(false);
  // const [ReloadAllMessage, setReloadAllMessage] = useState(false);

  const [isLoadMessage, setIsLoadMessage] = useState(false);
  const [chatMenuOpen, setChatMenuOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const styles = getStyles(theme, isExpand);
  const [allChat, setAllChat] = useState<Message[]>([
    {
      message:
        'Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World',
      role: 'user',
      status: 0,
    },
    { message: 'World', role: 'user', status: 0 },
    { message: 'Hi', role: 'assistant', status: 0 },
    {
      message:
        'Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World Hello World',
      role: 'assistant',
      status: 0,
    },
  ]);
  const [message, setMessage] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [allChat, chatMenuOpen]); ///chatMenuOpen

  useEffect(() => {
    fetchMessageData();
    const squareElement = document.getElementById('Frame38');
    if (squareElement) {
      const computedStyle = window.getComputedStyle(squareElement);
      const squareHeight = parseFloat(computedStyle.height);
      squareElement.style.width = squareHeight + 'px';
    }
  }, []);

  const fetchMessageData = async () => {
    setAllChat([]);
    const url = '/api/assistant/get_all_message';
    setIsLoadMessage(true);
    // setReloadAllMessage(false);
    try {
      const Backdata = await getBackendSrv().post(url);

      if (Backdata.status === 200) {
        if (Backdata.data.answer !== null && Backdata.data.answer !== '') {
          Backdata.data.answer.reverse().map((Data: Message) => {
            setAllChat((prevMessages) => [
              ...prevMessages,
              { message: Data.message, role: Data.role, status: Backdata.status },
            ]);
          });
        }
      } else if (Backdata.status === 5555) {
        setAllChat((prevMessages) => [
          ...prevMessages,
          {
            message: 'An error occurred, Please try again. ',
            role: 'assistant',
            status: Backdata.status,
          },
        ]);
      } else {
        setAllChat((prevMessages) => [
          ...prevMessages,
          {
            message: 'An error occurred, Please try again. ',
            role: 'assistant',
            status: Backdata.status,
          },
        ]);
        // setReloadAllMessage(true);
      }
    } catch (err) {
      // console.log(err);
      setAllChat((prevMessages) => [
        ...prevMessages,
        { message: 'An error occurred, Please try again. ', role: 'assistant', status: 0 },
      ]);
      // setReloadAllMessage(true);
    }

    setIsLoadMessage(false);

    // if (containerRef.current) {
    //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
    // }
  };

  // const reloadNewMessage = async () => {};

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleExpand = () => {
    setIsExpand(!isExpand)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // ตรวจสอบว่าผู้ใช้กด Enter
    if (event.key === 'Enter') {
      if (message.trim() === '') {
        return;
      } else {
        // ส่งข้อความไปยังคอมโพเนนท์ที่เรียกใช้งาน
        onSendMessage(message);
        // // ล้างข้อความในช่อง input
        setMessage('');
      }
    }
  };

  const handleSendMessage = () => {
    // ตรวจสอบว่าผู้ใช้กด Enter
    if (message.trim() === '') {
      return;
    } else {
      // ส่งข้อความไปยังคอมโพเนนท์ที่เรียกใช้งาน
      onSendMessage(message);
      // ล้างข้อความในช่อง input
      setMessage('');
    }
  };

  const onSendMessage = (massage: String) => {
    const requestData = {
      message: massage,
      role: 'user',
    };

    const Send = async () => {
      setIsLoadMessage(true);
      // setReloadLatestMessage(false);
      const Sendurl = '/api/assistant/receive_message';
      const Recvurl = '/api/assistant/get_all_message';
      setAllChat((prevMessages) => [...prevMessages, { message: massage.toString(), role: 'user', status: 200 }]);

      try {
        const check = await getBackendSrv().post(Sendurl, requestData);
        if (check.status === 200) {
          try {
            const Backdata = await getBackendSrv().post(Recvurl, requestData);

            if (Backdata.status === 200) {
              Backdata.data.answer.map((Data: Message, index: any) => {
                if (index === 0) {
                  setAllChat((prevMessages) => [
                    ...prevMessages,
                    { message: Data.message, role: Data.role, status: Backdata.status },
                  ]);
                }
              });
            }else if (Backdata.status === 502 || Backdata.status === 408) {
              /// set state reload get message
              // setReloadLatestMessage(true);
              setAllChat((prevMessages) => [
                ...prevMessages,
                {
                  message: 'An error occurred, Please try again. ',
                  role: 'assistant',
                  status: Backdata.status,
                },
              ]);
            } else {
              setAllChat((prevMessages) => [
                ...prevMessages,
                {
                  message: 'An error occurred, Please try again. ',
                  role: 'assistant',
                  status: Backdata.status,
                },
              ]);
            }
          } catch (err) {
            // console.log(err);
            setAllChat((prevMessages) => [
              ...prevMessages,
              { message: 'An error occurred, Please try again. ', role: 'assistant', status: 0 },
            ]);
            // setReloadAllMessage(true);
          }
        } else {
          setAllChat((prevMessages) => [
            ...prevMessages,
            { message: 'An error occurred, Please try again. ', role: 'assistant', status: 0 },
          ]);
        }
      } catch (err: any) {
        // console.log(err);
        if(err.status === 429){
          setAllChat((prevMessages) => [
            ...prevMessages,
            { message: 'You have exceeded your request limit in this month. If you want to extend the limit please contact support.',
              role: 'assistant',
              status: 200
            },
          ]);
        }else{
          setAllChat((prevMessages) => [
            ...prevMessages,
            { message: 'An error occurred, Please try again.', role: 'assistant', status: 0 },
          ]);
        }
      }

      setIsLoadMessage(false);
    };

    Send();
  };

  const autoAsk = (msg: String) => {
    setChatMenuOpen(true);
    onSendMessage(msg);
  }

  const options = [

    //{ label: 'FAQ', value: 0 },
    //{ label: 'Understanding Netka SOAR.',   value: 1,  details: "What is Netka SOAR and how does it work?" },
    { label: 'Getting Started.',            value: 2,  details: "How do I get started with Netka SOAR?" },
    //{ label: 'Monitoring security event.',  value: 3,  details: "How do I monitoring security event?" },
    { label: 'Security incident.',          value: 4,  details: "How do I create and manage security incident?" },
    //{ label: 'Automate response?',          value: 5,  details: "How can I automating response process?" },
    { label: 'User Roles and Permissions.', value: 6,  details: "How do I assign roles and permissions to users?" },
    { label: 'Configuring Alerts.',         value: 7,  details: "How do I configure alerts for specific security events?" },
    //{ label: 'Integration with SIEM.',      value: 8,  details: "How do I integrate Netka SOAR with a SIEM system?" },
    { label: 'Customizing Dashboards.',     value: 9,  details: "How can I customize dashboards to display relevant data?" },
    //{ label: 'Incident Response Workflow.', value: 10, details: "What is the workflow for responding to an incident?" },
    //{ label: 'Log Management.',             value: 11, details: "How do I manage and analyze logs in Netka SOAR?" },
    { label: 'Setting Up Notifications.',   value: 12, details: "How do I set up notifications for security events?" },
    //{ label: 'System Requirements.',        value: 13, details: "What are the system requirements for installing Netka SOAR?" },
    //{ label: 'Updating the Software.',      value: 14, details: "How do I update Netka SOAR to the latest version?" },
    //{ label: 'Backup and Recovery.',        value: 15, details: "How do I perform backup and recovery of Netka SOAR data?" },
    //{ label: 'Troubleshooting Issues.',     value: 16, details: "How do I troubleshoot common issues in Netka SOAR?" },
    //{ label: 'User Training.',              value: 17, details: "Where can I find training resources for Netka SOAR?" },
    { label: 'API Documentation.',          value: 18, details: "Where can I find the API documentation for Netka SOAR?" },
    //{ label: 'Creating Reports.',           value: 19, details: "How do I create and schedule reports in Netka SOAR?" },
    //{ label: 'Data Encryption.',            value: 20, details: "How is data encryption handled in Netka SOAR?" }

    // { label: 'Option with description', value: 1, description: 'this is a description' },
    // {
    //   label: 'Option with description and image',
    //   value: 2,
    //   description: 'This is a very elaborate description, describing all the wonders in the world.',
    //   imgUrl: 'https://placekitten.com/40/40',
    // },
  ];

  const [value, setValue] = useState<number | null>();

  return (
    <div id="Card">
      {chatMenuOpen ? (
        <div id="Wrapper" className={styles.wrapper}>
          <div className={styles.box}>
            <div id="Group38">
              <div className={cx(styles.top_bar, styles.background_top_bar)}>
                <div id="Frame45" className={styles.frame45}>
                  <div id="Frame38" className={styles.frame38}>
                    <div className={styles.group37} id="Group37 (For Logo Image)">
                      <div className={styles.circle}>
                        <img src={SOARlogo} alt="Prometheus Logo" height="80%" width="80%" />
                      </div>
                    </div>
                    <div id="Frame39" className={styles.frame39}>
                      SOAR ChatBot
                    </div>
                  </div>
                  <div id="Frame37" className={styles.frame37}>
                    <IconButton name="minus" color="white" onClick={() => setChatMenuOpen(false)} />
                    <IconButton name="sync" color="white" onClick={() => fetchMessageData()} />
                    {isExpand ? (
                      <IconButton name="angle-down" color="white" onClick={() => handleExpand()} />
                    ) : (
                      <IconButton name="angle-up" color="white" onClick={() => handleExpand()} />
                    )}
                    {/* <IconButton name="times" onClick={undefined} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div id="ChatBody">
              <div className={styles.chatBody}>
                <div className={styles.group40} ref={containerRef}>
                  {allChat.map((message, index) => (
                    <div key={index} className={undefined} id="Gap Gen">
                      {message.role === 'user' ? (
                        <div id="user" className={styles.user}>
                          <div>{message.role}</div>
                          <div className={styles.userMessageBox} id="message_box">
                            {message.message}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={undefined}
                          id="leftright"
                          style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
                        >
                          <div id="image" className={undefined} style={{ paddingTop: '28px' }}>
                            <div className={styles.oppo_circle}>
                              <img src={SOARlogo} alt="Prometheus Logo" height="80%" width="80%" />
                            </div>
                          </div>
                          <div id="textField">
                            <div id="assistant" className={styles.assistant}>
                              <div>{message.role}</div>
                              <div className={styles.assistantMessageBox} id="assistant_message_box">
                                {message.message}
                                {message.status === 200 ? (
                                  ''
                                ) : (
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconButton name="sync" onClick={() => fetchMessageData()} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {
                    //// Button for something ?
                  }
                  {isLoadMessage === true ? <LoadingPlaceholder text="" /> : <></>}
                </div>
              </div>
            </div>
            <div id="MessageBar">
              <div className={styles.messageBar}>
                <div className={styles.textInput} style={{}} id="Textinput">
                  <Select
                    options={options}
                    placeholder={"FAQ"}
                    value={value}
                    onChange={({ value }) => {
                      if(value !== 0) {autoAsk(options.find(t => t.value === value)!.details)}
                      setValue(null);
                    }}
                    allowCustomValue
                    width={16}
                  />
                  <Input
                    type="text"
                    placeholder="Reply to SOAR ChatBot..."
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    disabled={isLoadMessage}
                  />
                  <Button icon="angle-right" onClick={handleSendMessage} disabled={isLoadMessage}></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Button icon="comment-alt" size="lg" onClick={() => setChatMenuOpen(true)}></Button>
      )}
    </div>
  );
});

ChatsBot.displayName = 'ChatsBot';

const getStyles = (theme: GrafanaTheme2, isExpand: boolean) => ({
  /// ====> change full screen in here <=====
  /// width 95vw
  // height 95vh
  /// wid 300px
  wrapper: css`
    ${isExpand ? 'width: 95vw; height: 95vh; ' : 'width: 300px;'}
    @media screen and (max-width: 170px) {
      display: none;
    }

    @media screen and (max-height: 500px) {
      display: none;
    }
  `,
  /// W 20%
  /// H 50 %
  box: css``,
  /// ====> change full screen in here <=====
  // height 54vh
  top_bar: css`
    width: 100%;
    ${isExpand ? 'padding: 10px; ' : 'height: 54px;'}
    border-radius: 15px 15px 0px 0px;

    border-top: 1px solid rgba(47, 47, 47, 1);
    border-left: 1px solid rgba(47, 47, 47, 1);
    border-right: 1px solid rgba(47, 47, 47, 1);

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  `,
  background_top_bar: css`
    background: linear-gradient(180deg, #4daaff 0%, #0085ff 100%);
  `,
  // H 60%
  //height: 30px;
  frame45: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 60%;
  `,
  // H 100%
  frame38: css`
    gap: 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  group37: css`
    width: 30px;
    height: 30px;
  `,
  frame39: css`
    gap: 1px;
    width: 93px;
    height: 39px;
    display: flex;
    align-items: center;
    color: #FFFFF;
  `,
  // gap: 1px => 8px
  frame37: css`
    gap: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  `,
  /// ====> change full screen in here <=====
  // height 75vh
  chatBody: css`
    width: 100%;
    ${isExpand ? 'height: 75vh; ' : 'height: 382px;'}
    background: ${theme.colors.background.primary};
    border-left: 1px solid rgba(47, 47, 47, 1);
    border-right: 1px solid rgba(47, 47, 47, 1);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  `,
  ////// margin: 10px; => padding: 5px
  /// width: 320px;
  group40: css`
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
  /// background: linear-gradient(0deg, #202020, #202020), linear-gradient(0deg, #2f2f2f, #2f2f2f);
  /// ====> change full screen in here <=====
  // height 10vh
  messageBar: css`
    width: 100%;
    ${isExpand ? 'height: 10vh;' : 'height: 54px;'}
    border-radius: 0px 0px 15px 15px;
    border: 1px solid rgba(47, 47, 47, 1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.background.primary};
  `,
  user: css`
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: flex-end;
  `,
  userMessageBox: css`
    padding: 10px 15px 10px 15px;
    border-radius: 15px;
    gap: 5px;
    background: rgba(0, 133, 255, 1);
    display: flex;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  `,
  assistant: css`
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
  `,
  assistantMessageBox: css`
    padding: 10px 15px 10px 15px;
    border-radius: 15px;
    gap: 5px;
    background: ${theme.colors.background.secondary};
    display: flex;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  `,
  textInput: css`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
    padding: 5px;
    justify-content: center;
    align-items: center;
  `,
  circle: css`
    border-radius: 50%;
    background: ${theme.colors.background.primary};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  oppo_circle: css`
    border-radius: 50%;
    background: ${theme.isDark ? '#ffffff' : '#202020'};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
});
