import { css } from '@emotion/css';
import React, { FC } from 'react';

interface NavigationButtonsProps {
  onNext: () => void;
  onBack: () => void;
  disableNext: boolean;
  disableBack: boolean;
  CheckPlatform: boolean;
  CheckAgentName: boolean;
  CheckOsVersion: boolean;
  step: number;
}

export const NavigationButtons: FC<NavigationButtonsProps> = ({
  onNext,
  onBack,
  disableNext,
  disableBack,
  CheckPlatform,
  CheckAgentName,
  CheckOsVersion,
  step,
}) => {
  const styles = getStyles();

  const stepChecks = [CheckPlatform, CheckOsVersion, CheckAgentName];
  const isCheckDisabled = step < 4 ? stepChecks[step - 1] : false;

  console.log(
    'Check CheckPlatform , step , CheckAgentName , CheckOsVersion:',
    CheckPlatform,
    step,
    CheckAgentName,
    CheckOsVersion
  );

  return (
    <div className={styles.container}>
      {step !== 1 && (
        <button onClick={onBack} disabled={disableBack} className={styles.button}>
          Back
        </button>
      )}
      {step !== 7 && (
        <button onClick={onNext} disabled={isCheckDisabled || disableNext} className={`${styles.button} ${styles.nextButton}`}>
          Next
        </button>
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
    `,
    button: css`
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background-color: #0085ff;
      color: white;

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    `,
    nextButton: css`
      margin-left: auto; /* This ensures the next button is pushed to the right */
    `
  };
};
