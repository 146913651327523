import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {} from '@grafana/data';
import { KnowledgesState, Knowledges } from 'app/types';

export const initialState: KnowledgesState = {
  knowledges: [],
  newKnowledges: {
    user_id: 0,
    org_id: 0,
    topic: '',
    credit: '',
    content: '',
    created_at: new Date(),
    updated_at: new Date(),
  },
  knowledgesCreate: {
    user_id: 0,
    org_id: 0,
    topic: '',
    credit: '',
    content: '',
    created_at: new Date(),
    updated_at: new Date(),
  },
  knowledgesUpdate: {
    user_id: 0,
    org_id: 0,
    topic: '',
    credit: '',
    content: '',
    created_at: new Date(),
    updated_at: new Date(),
  },
  hasFetched: false,
  userRole: '',
  userLists: [],
  searchQuery: '',
  searchPage: 1,
};

const knowledgesSlice = createSlice({
  name: 'knowledges',
  initialState: initialState,
  reducers: {
    knowledgesLoaded: (state, action): KnowledgesState => {
      // console.log("test knowledgesLoaded check : ", action.payload);
      return { ...state, hasFetched: true, knowledges: action.payload };
    },
    knowledgesCreate: (state, action: PayloadAction<Knowledges>): KnowledgesState => {
      // console.log("test knowledgesCreate check : ", action.payload);
      return { ...state, knowledges: [...state.knowledges, action.payload] };
    },
    knowledgesUpdate: (state, action: PayloadAction<Knowledges>): KnowledgesState => {
      // console.log("test knowledgesUpdate check : ", action.payload);
      return { 
        ...state,
        knowledges: state.knowledges.map((knowledge) => (knowledge.id === action.payload.id ? action.payload : knowledge)),
      };
    },
    knowledgesDeleted: (state, action: PayloadAction<number>): KnowledgesState => {
      return {
        ...state,
        knowledges: state.knowledges.filter((knowledge) => knowledge.id !== action.payload),
      };
    },
    knowledgesLoadedById: (state, action): KnowledgesState => {
      // console.log("action", action)
      // console.log("test knowledgesLoaded check : ", action.payload);
      return { ...state, hasFetched: true, knowledgesUpdate: action.payload };
    },
    userRole:(state, action): KnowledgesState  => {
      return { ...state, userRole: action.payload };
    },
    usersLoadbyOrgId: (state, action): KnowledgesState => {
      // console.log("test securityIncidentsLoadbyid check : ",action.payload);
      return { ...state, userLists: action.payload };
    },
    setSearchQuery: (state, action): KnowledgesState => {
      return { ...state, searchQuery: action.payload };
    },
    setSearchPage: (state, action): KnowledgesState => {
      return { ...state, searchPage: action.payload };
    }, 
  },
});

export const {
    knowledgesLoaded,
    knowledgesCreate,
    knowledgesUpdate,
    knowledgesDeleted,
    knowledgesLoadedById,
    userRole,
    usersLoadbyOrgId,
    setSearchQuery,
    setSearchPage,
} = knowledgesSlice.actions;

export const knowledgesReducer = knowledgesSlice.reducer;

export default {
  knowledges: knowledgesReducer,
};
