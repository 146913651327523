import { css, cx } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { GrafanaTheme2, AppEvents } from '@grafana/data';
import { Button, Input, Label, useStyles2, useTheme2, ClipboardButton, Icon} from '@grafana/ui';
import { appEvents } from 'app/core/core';

import { TenantDetail } from './types';

interface SelectOsProps {
  os: string;
  setOsVersion: (value: string) => void;
}

interface UninstallingCommands {
  os: string;
  osVersion: string;
  haveMultiCommands: boolean;
  data: MultipleCommandGroup[];
}

interface MultipleCommandGroup {
  label: string;
  command: string[];
  disableCopyButton: boolean;
  /// osVersion : string[];
}

export interface Props {
  tenantDetail?: TenantDetail;
  hasLoading?: Boolean;
  selectedPlatform?: string;
  step?: number;
  osVersion: string;
  setOsVersion: (value: string) => void;
  agentName: string;
  setAgentName: (value: string) => void;
}

const SelectOsType: FC<SelectOsProps> = ({ os, setOsVersion }) => {
  let windowsType = [{ name: 'MSI 32/64 bits' }];
  let linux = [{ name: 'RPM amd64' }, { name: 'DEB amd64' }, { name: 'RPM aarch64' }, { name: 'DEB aarch64' }];

  const theme = useTheme2();
  const styles = useStyles2(theme.isDark ? getStylesisDark : getStylesisLight);

  const setOsVersionInComponent = (osType: string) => {
    setOsVersion(osType);
  };

  // const [pressedButtons, setPressedButtons] = useState(new Array(windowsType.length).fill(false));
  const [selectedItem, setSelectedItem] = useState('');

  const renderItems = () => {
    switch (os) {
      case 'windows':
        return windowsType.map((item, index) => (
          // <Button key={index} className={styles.marginButton} style={{ backgroundColor: '	#d3341e' }} onClick={() => setOsVersionInComponent(item.name)}>
          <Button
            key={index}
            className={cx(styles.marginButton, item.name === selectedItem && styles.pressedStyles)}
            onClick={() => {
              setOsVersionInComponent(item.name);
              setSelectedItem(item.name);
            }}
          >
            {item.name}
          </Button>
        ));
      case 'linux':
        return linux.map((item, index) => (
          <Button
            key={index}
            className={cx(styles.marginButton, item.name === selectedItem && styles.pressedStyles)}
            onClick={() => {
              setOsVersionInComponent(item.name);
              setSelectedItem(item.name);
            }}
          >
            {item.name}
          </Button>
        ));
      default:
        return (
          <div>
            <Button variant="destructive" fill="outline">
              Please select OS first
            </Button>
          </div>
        );
    }
  };

  return (
    <>
      <div className={cx(styles.topHeadContent, 'section-os')}>
        <Label>{/* <h4 className={cx(styles.textColor)}>Select OS version</h4> */}</Label>
        <div style={{ textAlign: 'center' }}>{renderItems()}</div>
      </div>
    </>
  );
};

export const InstallationGuideSiem: FC<Props> = ({
  tenantDetail,
  hasLoading,
  selectedPlatform,
  step,
  osVersion,
  setOsVersion,
  agentName,
  setAgentName,
}) => {
  // const [agentName, setAgentName] = useState<string>('');
  const [command, setComand] = useState<string>('');
  const [commandRunAgent, setComandRunAgent] = useState<string>('');
  const [commandUninstallAgent, setComandUninstallAgent] = useState<string>('');

  const [commandUninstallAgentMultiple] = useState<UninstallingCommands[]>([
    // Use this instade.
    {
      os: 'linux',
      osVersion: '*',
      haveMultiCommands: true,
      data: [
        { label: '# Yum ', command: ['yum remove wazuh-agent\n'], disableCopyButton: false },
        {
          label: '# APT ',
          command: ['apt-get remove wazuh-agent\n', 'apt-get remove --purge wazuh-agent\n'],
          disableCopyButton: false,
        },
        { label: '# ZYpp ', command: ['zypper remove wazuh-agent\n'], disableCopyButton: false },
        { label: '# APK ', command: ['apk del wazuh-agent\n'], disableCopyButton: false },
      ],
    },
  ]);

  const [os, setOs] = useState<string>(selectedPlatform || '');
  // const [osVersion, setOsVersion] = useState<string>('');

  useEffect(() => {
    if (selectedPlatform) {
      setOs(selectedPlatform);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    const generateComand = (os: string, osVersion: string) => {
      // ... rest of the function
    };

    generateComand(os, osVersion);
  }, [os, osVersion]);

  useEffect(() => {
    // Function to be called after state update
    generateComand(os, osVersion);
  });

  // useEffect(() => {
  //   // Function to be called after state update
  //   setOsVersion('');
  // }, [os]);
  // console.log("Check os:",os);
  // console.log("Check osVersion:",osVersion);
  // const;

  const generateComand = (os: string, osVersion: string) => {
    let command_install_agent = '';
    let command_run_agent = '';
    let command_uninstall_agent = '';
    //let command_uninstall_agent_group: MultipleCommandGroup[] = [];

    switch (os) {
      case 'windows':
        switch (osVersion) {
          case 'MSI 32/64 bits':
            command_install_agent +=
              'Invoke-WebRequest -Uri https://packages.wazuh.com/4.x/windows/wazuh-agent-4.7.4-1.msi -OutFile ${env:tmp}wazuh-agent.msi; msiexec.exe /i ${env:tmp}wazuh-agent.msi /q';
            break;
        }

        command_run_agent = 'NET START WazuhSvc';
        command_install_agent += ` WAZUH_MANAGER='${tenantDetail?.tenant_siem_ip}' WAZUH_REGISTRATION_PASSWORD='${tenantDetail?.tenant_siem_key}' WAZUH_AGENT_GROUP='default' WAZUH_AGENT_NAME='${agentName}' WAZUH_REGISTRATION_SERVER='${tenantDetail?.tenant_siem_ip}'`;
        command_uninstall_agent = "1. Open the search box on the taskbar and type 'Control panel' \n";
        command_uninstall_agent += "2. Select 'Control Panel' from the search results.\n";
        command_uninstall_agent += "3. Within the Control Panel, click on 'Programs'.\n";
        command_uninstall_agent += "4. Under the 'Programs and Features' section, choose 'Uninstall a program' \n";
        command_uninstall_agent += "5. Locate 'Wazuh Agent' in the list, right-click on it, and select 'Uninstall' \n";

        break;
      case 'linux':
        switch (osVersion) {
          case 'RPM amd64':
            command_install_agent += `curl -o wazuh-agent-4.7.4-1.x86_64.rpm https://packages.wazuh.com/4.x/yum/wazuh-agent-4.7.4-1.x86_64.rpm &&`;
            command_install_agent += ` sudo WAZUH_MANAGER='${tenantDetail?.tenant_siem_ip}' WAZUH_REGISTRATION_PASSWORD='${tenantDetail?.tenant_siem_key}' WAZUH_AGENT_GROUP='default' WAZUH_AGENT_NAME='${agentName}' rpm -ihv wazuh-agent-4.7.4-1.x86_64.rpm`;
            break;
          case 'DEB amd64':
            command_install_agent += `wget https://packages.wazuh.com/4.x/apt/pool/main/w/wazuh-agent/wazuh-agent_4.7.4-1_amd64.deb &&`;
            command_install_agent += ` sudo WAZUH_MANAGER='${tenantDetail?.tenant_siem_ip}' WAZUH_REGISTRATION_PASSWORD='${tenantDetail?.tenant_siem_key}' WAZUH_AGENT_GROUP='default' WAZUH_AGENT_NAME='${agentName}' dpkg -i ./wazuh-agent_4.7.4-1_amd64.deb`;
            break;
          case 'RPM aarch64':
            command_install_agent += `curl -o wazuh-agent-4.7.4-1.aarch64.rpm https://packages.wazuh.com/4.x/yum/wazuh-agent-4.7.4-1.aarch64.rpm &&`;
            command_install_agent += ` sudo WAZUH_MANAGER='${tenantDetail?.tenant_siem_ip}' WAZUH_REGISTRATION_PASSWORD='${tenantDetail?.tenant_siem_key}' WAZUH_AGENT_GROUP='default' WAZUH_AGENT_NAME='${agentName}' rpm -ihv wazuh-agent-4.7.4-1.aarch64.rpm`;

            break;
          case 'DEB aarch64':
            command_install_agent += `wget https://packages.wazuh.com/4.x/apt/pool/main/w/wazuh-agent/wazuh-agent_4.7.4-1_arm64.deb &&`;
            command_install_agent += ` sudo WAZUH_MANAGER='${tenantDetail?.tenant_siem_ip}' WAZUH_REGISTRATION_PASSWORD='${tenantDetail?.tenant_siem_key}' WAZUH_AGENT_GROUP='default' WAZUH_AGENT_NAME='${agentName}' dpkg -i ./wazuh-agent_4.7.4-1_arm64.deb`;
            break;
        }

        command_run_agent =
          ' sudo systemctl daemon-reload\n sudo systemctl enable wazuh-agent\n sudo systemctl start wazuh-agent';

        command_uninstall_agent = '# Yum\n yum remove wazuh-agent\n\n';
        command_uninstall_agent += '# APT\n apt-get remove wazuh-agent\n apt-get remove --purge wazuh-agent\n\n';
        command_uninstall_agent += '# ZYpp\n zypper remove wazuh-agent\n\n';
        command_uninstall_agent += '# APK\n apk del wazuh-agent';

        break;
    }

    setComand(command_install_agent);
    setComandRunAgent(command_run_agent);
    setComandUninstallAgent(command_uninstall_agent);
    //setComandUninstallAgentMultiple(command_uninstall_agent_group);
  };

  const styles = useStyles2(getStylesisDark);

  const onClipboardCopy = () => {
    appEvents.emit(AppEvents.alertSuccess, ['Content copied to clipboard']);
  };

  return (
    <>
      <div className={styles.container_content}>
        <div className={styles.item_content}>
          {step === 2 && (
            <div className={cx(styles.topHeadContent, 'section-os-version')}>
              <SelectOsType os={os} setOsVersion={setOsVersion} />
            </div>
          )}
          {step === 3 && (
            <div className={cx(styles.topHeadContent, styles.centerContent, styles.noWrapLabel)}>
              <Label>
                <h4 className={cx(styles.textColor)}> 
                  Assign a name to the agent 
                  {/* <Tooltip content="Please enter a unique name to avoid duplication." placement="top">
                    <Button
                      variant="secondary"
                      size="sm"
                      className={cx(styles.helpButton)}
                      icon="info-circle"
                    />
                  </Tooltip> */}
                </h4>
              </Label>
              <Label>
                <h6>
                  Please enter a unique name to avoid duplication.
                </h6>
              </Label>
              <Input
                width={50}
                aria-label="Filter expression for label"
                onChange={(element) => {
                  setAgentName(element.currentTarget.value);
                }}
                placeholder="Name of agent"
              />
            </div>
          )}
          {step === 4 && (
            <>
              <div className={cx(styles.topHeadContent, styles.noWrapLabel)}>
                <Label>
                  <h4 className={cx(styles.textColor)}>
                    Install compliance and security agent, run the following command:
                  </h4>
                </Label>
                {tenantDetail?.tenant_siem_ip === 'SIEM provisioning processing, please try later' ||
                tenantDetail?.tenant_siem_ip === undefined ? (
                  <h4 style={{ color: 'yellow' }}>Waiting for provisioning please try later.</h4>
                ) : (
                  <pre className={styles.commandPre}>
                    <p>{command}</p>
                    <ClipboardButton
                      variant="primary"
                      getText={() => {
                        let commands = `${command}`;
                        return commands;
                      }}
                      onClipboardCopy={onClipboardCopy}
                      onClipboardError={(e) => {
                        appEvents.emit(AppEvents.alertError, ['Error while copying password', e]);
                      }}
                    >
                      <Icon name="copy" /> Copy
                    </ClipboardButton>
                  </pre>
                )}
              </div>
              <div className={cx(styles.topHeadContent, styles.noWrapLabel)}>
                <Label>
                  <h4 className={cx(styles.textColor)}>Run compliance and security service:</h4>
                </Label>
                <div className="run-command">
                  <pre className={styles.commandPre}>
                    <p>{commandRunAgent}</p>
                    <ClipboardButton
                      variant="primary"
                      getText={() => {
                        let command = `${commandRunAgent}`;
                        return command;
                      }}
                      onClipboardCopy={onClipboardCopy}
                      onClipboardError={(e) => {
                        appEvents.emit(AppEvents.alertError, ['Error while copying password', e]);
                      }}
                    >
                      <Icon name="copy" /> Copy
                    </ClipboardButton>
                  </pre>
                </div>
              </div>
              <div className={cx(styles.topHeadContent, styles.noWrapLabel)}>
                <Label>
                  <h4 style={{ textAlign: 'center' }}>If you want to uninstall Wazuh agent service:</h4>
                </Label>
                <div className="run-command">
                  {os === 'windows' ? (
                    <pre className={styles.commandPre}>
                      <p>{commandUninstallAgent}</p>
                    </pre>
                  ) : (
                    // <>
                    //   {commandUninstallAgentMultiple.map((group) => {
                    //     if (group.os === os && (group.osVersion === osVersion || group.osVersion === "*")) {
                    //       if ( group.haveMultiCommands ) {
                    //         return group.data.map((data) => (
                    //           <pre className={styles.commandPre} key={data.label}>
                    //             <p>{data.label}</p>
                    //             {data.command.map((commands, index, array) => (
                    //               <div key={index}>
                    //                 <p>{commands}</p>
                    //                 <ClipboardButton
                    //                   variant="primary"
                    //                   getText={() => commands}
                    //                   onClipboardCopy={onClipboardCopy}
                    //                   onClipboardError={(e) => {
                    //                     appEvents.emit(AppEvents.alertError, ['Error while copying command', e]);
                    //                   }}
                    //                 >
                    //                   <Icon name="copy" /> Copy
                    //                 </ClipboardButton>
                    //                 {index + 1 !== array.length ? <p>{'\n'}</p> : null}
                    //               </div>
                    //             ))}
                    //           </pre>
                    //         ));
                    //       }
                    //       else{
                    //         return (
                    //           <pre className={styles.commandPre}>
                    //             <p>{commandUninstallAgent}</p>
                    //           </pre>
                    //         )
                    //       }
                    //     }
                    //     return null;
                    //   })}
                    // </>

                    <>
                      {commandUninstallAgentMultiple.map((group) => {
                        if (group.os === os && (group.osVersion === osVersion || group.osVersion === '*')) {
                          if (group.haveMultiCommands) {
                            return group.data.map((data) => (
                              <pre className={styles.commandPre} key={data.label}>
                                <p>{data.label}</p>
                                {data.command.map((commands, index, array) => (
                                  <div key={index}>
                                    <p>{commands}</p>
                                    {data.disableCopyButton ? (
                                      <></>
                                    ) : (
                                      <ClipboardButton
                                        variant="primary"
                                        getText={() => commands}
                                        onClipboardCopy={onClipboardCopy}
                                        onClipboardError={(e) => {
                                          appEvents.emit(AppEvents.alertError, ['Error while copying command', e]);
                                        }}
                                      >
                                        <Icon name="copy" /> Copy
                                      </ClipboardButton>
                                    )}

                                    {index + 1 !== array.length && !data.disableCopyButton ? <p>{'\n'}</p> : null}
                                  </div>
                                ))}
                              </pre>
                            ));
                          } else {
                            return (
                              <pre className={styles.commandPre}>
                                <p>{commandUninstallAgent}</p>
                              </pre>
                            );
                          }
                        }
                        return null;
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const getStylesisDark = (theme: GrafanaTheme2) => {
  return {
    helpButton: css`
      margin-left: 8px;
      padding: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: ${theme.colors.text.primary};
      
      &:hover {
        background-color: transparent;
      }
    `,
    item_content: css`
      padding: 20px;
    `,
    container_content: css`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 20px;
      margin-top: 20px;
    `,
    header_getting_start: css`
      margin-top: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 191px;
      background: linear-gradient(180deg, #4daaff 0%, #0085ff 100%);
      border: 1px solid rgba(204, 204, 220, 0.07);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      font-size: 64px;
      color: #FFFFF;
    `,
    marginButton: css`
      margin-right: 10px;
      background-color: #2d2d2d;
      width: 240px;
      height: 80px;
      font-size: 18px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      &:hover {
        background-color: #2d2d2d;
      }
    `,

    topHeadContent: css`
      margin-top: 20px;
      font-size: 20px;
    `,

    centerContent: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    `,

    noWrapLabel: css`
      white-space: nowrap;
    `,

    commandPre: css`
      max-width: 100%;
      overflow: auto;
    `,
    warningMessage: css`
      color: red;
      font-size: 14;
    `,
    textColor: css`
      color: ${theme.colors.text.primary};
    `,
    pressedStyles: css`
      border: 2px solid #0085ff;
      background-color: #2d2d2d;
    `,
  };
};

const getStylesisLight = (theme: GrafanaTheme2) => {
  return {
    helpButton: css`
     margin-left: 8px;
      padding: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: ${theme.colors.text.primary};
      
      &:hover {
        background-color: transparent;
      }
    `,
    item_content: css`
      padding: 20px;
    `,
    container_content: css`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 20px;
      margin-top: 20px;
    `,
    header_getting_start: css`
      margin-top: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 191px;
      background: linear-gradient(180deg, #4daaff 0%, #0085ff 100%);
      border: 1px solid rgba(204, 204, 220, 0.07);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      font-size: 64px;
      color: #000000;
    `,
    marginButton: css`
      margin-right: 10px;
      background-color: #f5f5f5;
      color: #000000;
      width: 240px;
      height: 80px;
      font-size: 18px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      &:hover {
        color: #000000;
        background-color: #f5f5f5;
      }
    `,

    topHeadContent: css`
      margin-top: 20px;
      font-size: 20px;
    `,
    centerContent: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    `,

    noWrapLabel: css`
      white-space: nowrap;
    `,
    commandPre: css`
      max-width: 700px;
      overflow: auto;
    `,
    warningMessage: css`
      color: red;
      font-size: 14;
    `,
    textColor: css`
      color: ${theme.colors.text.primary};
    `,
    pressedStyles: css`
      border: 2px solid #0085ff;
      background-color: #dedede;
    `,
  };
};

export default InstallationGuideSiem;
