import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LogFile, LogFilesState } from 'app/types';

export const initialState: LogFilesState = {
  files: [],
  searchQuery: '',
  searchPage: 1,
  hasFetched: false,
};

const logFilesSlice = createSlice({
  name: 'logFiles',
  initialState,
  reducers: {
    logFilesLoaded: (state, action: PayloadAction<LogFile[]>): LogFilesState => {
      return { ...state, hasFetched: true, files: action.payload };
    },
    setLogFilesSearchQuery: (state, action: PayloadAction<string>): LogFilesState => {
      // reset searchPage otherwise search results won't appear
      return { ...state, searchQuery: action.payload, searchPage: initialState.searchPage };
    },
    setLogFilesSearchPage: (state, action: PayloadAction<number>): LogFilesState => {
      return { ...state, searchPage: action.payload };
    },
  },
});

export const { setLogFilesSearchPage, setLogFilesSearchQuery, logFilesLoaded } = logFilesSlice.actions;

export const logFilesReducer = logFilesSlice.reducer;

export default {
  logFiles: logFilesReducer,
};
