import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {} from '@grafana/data';
import { CommentState, Comment, UserIdLogin } from 'app/types';

export const initialState: CommentState = {
  comments: [],
  userIdLogin: {
    user_id: 0,
    login: '',
  },
  newComment: {
    id: 0,
    group_id: 0,
    group_name: '',
    user_id: 0,
    org_id: 0,
    content: '',
    created_at: new Date(),
    updated_at: new Date(),
    login: '',
  },
  hasFetched: false,
  userRole: '',
  searchQuery: '',
  searchPage: 1,
  commentUpdate: false,
  commentUpdateId: 0,
};

const commentSlice = createSlice({
  name: 'comment',
  initialState: initialState,
  reducers: {
    commentLoaded: (state, action): CommentState => {
      // console.log("test knowledgesLoaded check : ", action.payload);
      return { ...state, hasFetched: true, comments: action.payload };
    },
    setSearchQuery: (state, action): CommentState => {
      return { ...state, searchQuery: action.payload };
    },
    setSearchPage: (state, action): CommentState => {
      return { ...state, searchPage: action.payload };
    },
    addComment: (state, action: PayloadAction<Comment>): CommentState => {
      return { ...state, comments: [...state.comments, action.payload] };
    },
    removeComment: (state, action: PayloadAction<number>): CommentState => {
      return {
        ...state,
        comments: state.comments.filter((comment) => comment.id !== action.payload),
      };
    },
    editComment: (state, action: PayloadAction<Comment>): CommentState => {
      const index = state.comments.findIndex((comment) => comment.id === action.payload.id);
      if (index !== -1) {
        state.comments[index] = action.payload; // Update the comment in the array
      }
      return state;
    },
    setCommentCheckUpdate: (state, action: PayloadAction<boolean>): CommentState => {
      return { ...state, commentUpdate: action.payload }; // Update the `commentUpdate` status
    },
    setCommentCheckUpdateId: (state, action: PayloadAction<number>): CommentState => {
      return { ...state, commentUpdateId: action.payload }; // Update the `commentUpdate` status
    },
    userLoaded: (state, action: PayloadAction<UserIdLogin>): CommentState => {
      return { ...state, userIdLogin: action.payload };
    },
    resetStateComment: () => initialState,
  },
});

export const {
  commentLoaded,
  setSearchQuery,
  setSearchPage,
  addComment,
  removeComment,
  editComment,
  setCommentCheckUpdate,
  setCommentCheckUpdateId,
  userLoaded,
  resetStateComment,
} = commentSlice.actions;

export const commentReducer = commentSlice.reducer;

export default {
  comment: commentReducer,
};
