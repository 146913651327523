import { cx, css, keyframes } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, styleMixins } from '@grafana/ui';

import { Branding } from '../Branding/Branding';
import { Footer } from '../Footer/Footer';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox: FC<InnerBoxProps> = ({ children, enterAnimation = true }) => {
  const loginStyles = useStyles2(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

export const LoginLayout: FC = ({ children }) => {
  const loginStyles = useStyles2(getLoginStyles);
  const subTitle = Branding.GetLoginSubTitle();
  const [startAnim, setStartAnim] = useState(false);

  useEffect(() => setStartAnim(true), []);

  return (
    <Branding.LoginBackground className={cx(loginStyles.container, startAnim && loginStyles.loginAnim)}>
      {/* <img className={loginStyles.logoNetka} src="public/img/netka-logo.svg" alt="Netka" /> */}
      <div className={cx(loginStyles.loginContent, Branding.LoginBoxBackground(), 'login-content-box')}>
        <div className={loginStyles.loginLogoWrapper}>
          <Branding.LoginLogo className={loginStyles.loginLogo} />
          <div className={loginStyles.titleWrapper}>
            <h1 className={loginStyles.mainTitle}>{Branding.LoginTitle}</h1>
            {subTitle && <h3 className={loginStyles.subTitle}>{Branding.GetLoginSubTitle()}</h3>}
          </div>
        </div>
        <div className={loginStyles.loginOuterBox}>{children}</div>
        <div className={cx(loginStyles.contactSupportSection, loginStyles.linkEmailContact)}>
          <a href="mailto: nlg.saas@netkasystem.com" style={{ paddingTop: '30px' }}>
            <span style={{ verticalAlign: 'top', marginRight: '5.5px' }}>
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 0.75C2.33696 0.75 1.70107 1.01339 1.23223 1.48223C0.763392 1.95107 0.5 2.58696 0.5 3.25V3.50125L8 7.54L15.5 3.5025V3.25C15.5 2.58696 15.2366 1.95107 14.7678 1.48223C14.2989 1.01339 13.663 0.75 13 0.75H3ZM15.5 4.92125L8.29625 8.8C8.2052 8.84901 8.10341 8.87467 8 8.87467C7.89659 8.87467 7.7948 8.84901 7.70375 8.8L0.5 4.92125V10.75C0.5 11.413 0.763392 12.0489 1.23223 12.5178C1.70107 12.9866 2.33696 13.25 3 13.25H13C13.663 13.25 14.2989 12.9866 14.7678 12.5178C15.2366 12.0489 15.5 11.413 15.5 10.75V4.92125Z"
                  fill="url(#paint0_linear_133_370)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_133_370"
                    x1="8"
                    y1="0.75"
                    x2="8"
                    y2="13.25"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#339dff" />
                    <stop offset="0.52" stopColor="#1a91ff" />
                    <stop offset="1" stopColor="#0085FF" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span style={{ color: '#0085FF' }}>Technical Supports</span>
          </a>
        </div>
      </div>
      <Footer />
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
  transform: translate(-60px, 0px);
}

to{
  opacity: 1;
  transform: translate(0px, 0px);
}`;

export const getLoginStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      minHeight: '100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      minWidth: '100%',
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    }),
    loginAnim: css`
      &:before {
        opacity: 1;
      }

      .login-content-box {
        opacity: 1;
      }
    `,
    submitButton: css`
      justify-content: center;
      width: 100%;
    `,
    loginLogo: css`
      width: 100%;
      max-width: 300px;
      margin-bottom: 15px;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.lg)} {
        max-width: 300px;
      }
    `,
    loginLogoWrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing(3)};
    `,
    titleWrapper: css`
      text-align: center;
    `,
    mainTitle: css`
      font-size: 22px;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        font-size: 20px;
        font-weight: bold;
      }
    `,
    subTitle: css`
      font-size: ${theme.typography.size.md};
      color: ${theme.colors.text.secondary};
    `,
    loginContent: css`
      width: 466px;
      // width: calc(100% - 2rem);
      display: flex;
      align-items: stretch;
      flex-direction: column;
      position: relative;
      justify-content: flex-start;
      z-index: 1;
      min-height: 100%;
      max-height: 100%
      border-radius: ${theme.shape.borderRadius(4)};
      padding: ${theme.spacing(2, 0)};
      opacity: 0;
      transition: opacity 0.5s ease-in-out;



      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        // 500px
        justify-content: center;
        padding-top: 65px;
      }

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.lg)} { 
        // 900px
        // width: 100%;
        min-height: 100%;
        justify-content: center;
        padding-top: 65px;
      }
    `,
    loginOuterBox: css`
      display: flex;
      overflow-y: scroll;
      align-items: top;
      justify-content: center;
    `,
    loginInnerBox: css`
      padding: ${theme.spacing(2)};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 415px;
      width: 100%;
      transform: translate(0px, 0px);
      transition: 0.25s ease;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
    contactSupportSection: css`
      // height: 100%;
      display: flex;
      margin: 0 auto 20px auto;
      // overflow-y: hidden;
      align-items: end;
      justify-content: end;
      color: #0085FF;
    `,
    linkEmailContact: css`
      a:hover {
        color: #08aa4e;
      }
      a {
        color: #08aa4e;
      }
    `,
    logoNetka: css`
      position: absolute;
      left: 54px;
      top: 48px;
      width: 127px;
      height: 77px;
    `,
  };
};
