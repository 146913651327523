import { css } from '@emotion/css';
import classnames from 'classnames';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { IconButton, useTheme2 } from '@grafana/ui';

export interface Props {
  className?: string;
  isExpanded: boolean;
  onClick: () => void;
}

export const NavBarToggle = ({ className, isExpanded, onClick }: Props) => {
  const theme = useTheme2();
  const styles = getStyles(theme);

  return (
    // <button
    //   aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
    //   className={classnames(className, styles.button)}
    //   onClick={onClick}
    // >
    //   <img src="./public/img/logo_SOAR.svg" alt="Menu Icon" className={styles.icon} />
    // </button>
    // <div
    //   aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
    //   className={classnames(className, styles.layout)}
    //   onClick={onClick}
    // >
    //   <img src="./public/img/logo_SOAR.svg" alt="Menu Icon" className={styles.icon} />
    // </div>
    // <img src="./public/img/logo_SOAR.svg" alt="Menu Icon" className={styles.icon} aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
    //   // className={classnames(className, styles.layout)}
    //   onClick={onClick}/>
    // <button
    //   aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
    //   className={classnames(className, styles.layout)}
    //   onClick={onClick}
    // >
    //   <img src="./public/img/logo_SOAR.svg" alt="Menu Icon" className={styles.icon} aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
    //   // className={classnames(className, styles.layout)}
    //   onClick={onClick}/>
    // </button>
    // <IconButton
    //   aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
    //   name="bars"
    //   size="xl"
    //   className={classnames(className, styles.layout)}
    //   onClick={onClick}
    // />
    <div className={classnames(className, styles.container)}>
      <img src="./public/img/logo_SOAR.svg" alt="Menu Icon" className={styles.icon} />
      <IconButton
        aria-label={isExpanded ? 'Close navigation menu' : 'Open navigation menu'}
        name="bars"
        size="xl"
        className={styles.button}
        onClick={onClick}
      />
    </div>
  );
};

NavBarToggle.displayName = 'NavBarToggle';

const getStyles = (theme: GrafanaTheme2) => ({
  // icon: css({
  //   backgroundColor: theme.colors.background.secondary,
  //   border: `1px solid ${theme.colors.border.weak}`,
  //   borderRadius: '50%',
  //   marginRight: 0,
  //   zIndex: theme.zIndex.sidemenu + 1,

  //   [theme.breakpoints.down('md')]: {
  //     display: 'none',
  //   },
  // }),
  // button: css({
  //   border: 'none', // Remove button border
  //   background: 'none', // Remove button background
  //   padding: 0, // Remove button padding
  //   cursor: 'pointer', // Change cursor to pointer
  //   display: 'flex', // Make the button a flex container
  //   alignItems: 'center', // Center items vertically
  //   height: '32px', // Adjust button height
  // }),
  // icon: css({
  //   width: '24px', // Adjust width of the icon if needed
  //   height: '24px', // Adjust height of the icon if needed
  //   marginRight: '8px', // Optional: Add spacing between icon and text
  // }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the left
    zIndex: theme.zIndex.sidemenu + 1,
  }),
  icon: css({
    width: '24px', // Adjust width of the icon if needed
    height: '24px', // Adjust height of the icon if needed
    marginBottom: '18px', // Add spacing between the image and button
  }),
  button: css({
    zIndex: theme.zIndex.sidemenu + 1,
  }),
  layout: css({
    zIndex: theme.zIndex.sidemenu + 1,
  }),
});
