import { css, cx } from '@emotion/css';
import { useLingui } from '@lingui/react';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { OverlayContainer, useOverlay } from '@react-aria/overlays';
import React, { useRef, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { useLocalStorage } from 'react-use';

import { GrafanaTheme2, NavModelItem,/*  textUtil */} from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { CollapsableSection, CustomScrollbar, Icon, IconName, Switch, useStyles2, useTheme2 } from '@grafana/ui';
import { PreferencesService } from 'app/core/services/PreferencesService';
import { contextSrv } from 'app/core/services/context_srv';

import { Branding } from '../Branding/Branding';

import { NavBarItemWithoutMenu } from './NavBarItemWithoutMenu';
import { NavBarMenuItem } from './NavBarMenuItem';
import { NavBarToggle } from './NavBarToggle';
import { NavFeatureHighlight } from './NavFeatureHighlight';
import menuItemTranslations from './navBarItem-translations';
import { isMatchOrChildMatch } from './utils';

const MENU_WIDTH = '350px';

export interface Props {
  activeItem?: NavModelItem;
  isOpen: boolean;
  navItems: NavModelItem[];
  setMenuAnimationInProgress: (isInProgress: boolean) => void;
  onClose: () => void;
}

export function NavBarMenu({ activeItem, isOpen, navItems, onClose, setMenuAnimationInProgress }: Props) {
  const theme = useTheme2();

  const [statusThemeDark, setStatusTheme] = useState(theme.isDark);
  const [menuHelp, setmenuHelp] = useState(false);
  const [menuProfile, setProfileOpen] = useState(false);

  const styles = getStyles(theme);
  const ANIMATION_DURATION = theme.transitions.duration.standard;
  const animStyles = getAnimStyles(theme, ANIMATION_DURATION);
  const ref = useRef(null);
  const { dialogProps } = useDialog({}, ref);

  const handleChangeTheme = async (statusTheme: boolean) => {
    const service = new PreferencesService('org');
    let themeDark = 'dark';
    setStatusTheme(statusTheme);
    if (statusTheme === false) {
      themeDark = 'light';
    }
    await service.update({
      theme: themeDark,
      timezone: '',
      weekStart: '',
      locale: '',
      queryHistory: {
        homeTab: '',
      },
    });
    window.location.reload();
  };
  const { overlayProps, underlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen,
      onClose,
    },
    ref
  );

  const helpIcon: NavModelItem[] = [
    {
      text: 'Documentation',
      icon: 'document-info',
      url: 'https://log-management.netkasystem.com',
      target: '_blank',
    },
    {
      text: 'Support',
      icon: 'question-circle',
      url: 'https://support.netkasystem.co.th/Login',
      target: '_blank',
    },
  ];

  const profileIcon: NavModelItem[] = [
    {
      id: 'profile/settings',
      text: 'Preferences',
      icon: 'sliders-v-alt',
      url: '/profile',
    },
    {
      id: 'profile/notifications',
      text: 'Notification history',
      icon: 'bell',
      url: '/profile/notifications',
    },
    {
      id: 'sign-out',
      text: 'Sign out',
      icon: 'arrow-from-right',
      url: '/logout',
      target: '_self',
      hideFromTabs: true,
    },
  ];

  // const handleLogout = () => {
  //   window.location.href = textUtil.sanitizeUrl('/logout');
  // };

  // const signoutItem = {
  //   id: 'signout',
  //   text: 'Sign Out',
  //   icon: 'signout',
  //   onClick: handleLogout,
  // };

  return (
    <OverlayContainer>
      <FocusScope contain restoreFocus autoFocus>
        <CSSTransition
          onEnter={() => setMenuAnimationInProgress(true)}
          onExited={() => setMenuAnimationInProgress(false)}
          appear={isOpen}
          in={isOpen}
          classNames={animStyles.overlay}
          timeout={ANIMATION_DURATION}
        >
          <div data-testid="navbarmenu" ref={ref} {...overlayProps} {...dialogProps} className={styles.container}>
            {/* <div className={styles.mobileHeader}>
              <Icon name="bars" size="xl" />
              <IconButton
                aria-label="Close navigation menu"
                name="times"
                onClick={onClose}
                size="xl"
                variant="secondary"
              />
            </div> */}

            <nav className={styles.content}>
              <CustomScrollbar hideHorizontalTrack>
                <div style={{ marginTop: '20px' }}>
                  {/* <img
                    className={styles.logo_nlg_modal}
                    src={'public/img/' + (theme.isDark ? 'nlg_logo.svg' : 'nlg_logo_dark.svg')}
                    alt="Grafana"
                  /> */}

                  <NavBarToggle
                    className={styles.menuCollapseIcon}
                    isExpanded={isOpen}
                    onClick={() => {
                      reportInteraction('grafana_navigation_collapsed');
                      onClose();
                    }}
                  />
                  {/* <Icon className={styles.setttingIcon} name="cog" size="xl" /> */}
                </div>

                <ul className={styles.itemList}>
                  {navItems
                    .filter((link) => link.id !== 'profile' && link.id !== 'help')
                    .map((link) => (
                      <NavItem link={link} onClose={onClose} activeItem={activeItem} key={link.text} />
                    ))}
                  <li className={styles.listItemDarkMode}>
                    <div className={styles.lineAfterButtonSwitchTheme}></div>
                  </li>
                  <li className={styles.listItemDarkMode}>
                    <div className={cx(styles.darkModeIcon, 'gicon-theme')} />

                    <div className={styles.columnMenu} style={{ paddingLeft: '5px' }}>
                      <div>Dark Mode</div>
                      <div className={styles.marginIconList}>
                        <Switch value={statusThemeDark} onChange={(e) => handleChangeTheme(e.currentTarget.checked)} />
                      </div>
                    </div>
                  </li>
                </ul>
                <div className={styles.footerSidebar}>
                  {menuHelp && (
                    <div className={styles.helpContainer}>
                      {helpIcon.map((item) => (
                        <div key={item.id}>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ float: 'right', marginRight: '30px', marginBottom: '10px', color: 'white' }}
                          >
                            {item.text}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}

                  {menuProfile && (
                    <div className={styles.helpContainer}>
                      {profileIcon.map((item) => (
                        <div key={item.id}>
                          <a
                            href={item.url}
                            target={item.target}
                            onClick={onClose}
                            rel="noreferrer"
                            style={{ float: 'right', marginRight: '30px', marginBottom: '10px', color: 'white' }}
                          >
                            {item.text}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className={styles.profileBar}>
                    {/* <Icon name={'gf-theme'} className={styles.darkModeIcon} size="xl" /> */}
                    <div>
                      <span className={styles.profileBarImage}>
                        <img src="/avatar/46d229b033af06a191ff2267bca9ae56" alt="" />
                      </span>
                    </div>
                    <div className={cx(styles.columnMenu)} style={{ paddingLeft: '5px' }}>
                      <div>
                        <div className={styles.textOverflow} style={{ fontSize: '16px', fontWeight: 'bolder' }}>
                          {contextSrv.user.email}
                        </div>
                        <div className={styles.textOverflow} style={{ fontSize: '12px' }}>
                          {contextSrv.user.orgName}
                        </div>
                      </div>
                      <Icon
                        name={'question-circle'}
                        size={'xl'}
                        className={styles.profileBarIcon}
                        onClick={() => {
                          if (menuProfile === true) {
                            setProfileOpen(!menuProfile);
                          }
                          setmenuHelp(!menuHelp);
                        }}
                      />
                      <Icon
                        name={'ellipsis-v'}
                        size={'xl'}
                        className={styles.darkModeIcon}
                        style={{ paddingTop: '5px' }}
                        onClick={() => {
                          if (menuHelp === true) {
                            setmenuHelp(!menuHelp);
                          }
                          setProfileOpen(!menuProfile);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </CustomScrollbar>
            </nav>
          </div>
        </CSSTransition>
      </FocusScope>
      <CSSTransition appear={isOpen} in={isOpen} classNames={animStyles.backdrop} timeout={ANIMATION_DURATION}>
        <div className={styles.backdrop} {...underlayProps} />
      </CSSTransition>
    </OverlayContainer>
  );
}

NavBarMenu.displayName = 'NavBarMenu';

const getStyles = (theme: GrafanaTheme2) => ({
  textOverflow: css({
    width: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      width: '180px',
    },
  }),
  footerSidebar: css({
    marginTop: '70%',
  }),
  helpContainer: css({
    paddingTop: '15px',
    paddingBottom: '8px',
    marginLeft: 'auto',
    display: 'grid',
    color: '#FFFFFF',
    background: theme.colors.nlgColor.main,
    borderRadius: '15px 15px 0px 0px',
    width: '60%',
  }),
  helpList: css({
    top: '20px',
    position: 'absolute',
    left: '15px',
    img: {
      borderRadius: '50%',
      height: '24px',
      width: '24px',
    },
  }),

  profileBarImage: css({
    top: '20px',
    position: 'absolute',
    left: '15px',
    img: {
      borderRadius: '50%',
      height: '24px',
      width: '24px',
    },
  }),

  profileBar: css({
    color: theme.colors.text.white,
    paddingTop: '15px',
    paddingBottom: '10px',
    backgroundColor: theme.colors.nlgColor.main,
    display: 'grid',
    position: 'relative',
    gridAutoFlow: 'column',
    gridTemplateColumns: '56px minmax(calc(350px - 56px), auto)',
  }),
  profileBarIcon: css({
    paddingTop: '5px',
    position: 'relative',
    left: '50px',
    [theme.breakpoints.down('md')]: {
      left: '150px',
    },
  }),
  marginIconList: css({
    marginLeft: '120px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '320px',
    },
  }),
  lineAfterButtonSwitchTheme: css({
    width: '212px',
    height: '47px',
    position: 'relative',
    left: '60px',
    borderTop: '1px solid #323232',
    [theme.breakpoints.down('md')]: {
      width: '512px',
    },
  }),
  columnMenu: css({
    display: 'grid',
    position: 'relative',
    gridAutoFlow: 'column',
  }),
  listItemDarkMode: css({
    display: 'grid',
    position: 'relative',
    gridAutoFlow: 'column',
    gridTemplateColumns: '56px minmax(calc(350px - 56px), auto)',
  }),
  darkModeIcon: css({
    // color: theme.isDark ? 'red' : 'red',
    backgroundRepeat: 'no-repeat',

    display: 'static',
    position: 'relative',
    left: '20px',
    top: '2px',
  }),

  logo_nlg_modal: css({
    width: '188px',
    height: '70px',
    position: 'absolute',
    left: '75px',
  }),
  backdrop: css({
    backdropFilter: 'blur(1px)',
    backgroundColor: theme.components.overlay.background,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.modalBackdrop,
  }),
  container: css({
    display: 'flex',
    bottom: 0,
    flexDirection: 'column',
    left: 0,
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(1.5),
    right: 0,
    zIndex: theme.zIndex.modal,
    position: 'fixed',
    top: 0,
    boxSizing: 'content-box',
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.colors.border.weak}`,
      right: 'unset',
    },
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  }),
  mobileHeader: css({
    borderBottom: `1px solid ${theme.colors.border.weak}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  itemList: css({
    marginTop: '30px',
    display: 'grid',
    gridAutoRows: `minmax(${theme.spacing(6)}, auto)`,
    minWidth: MENU_WIDTH,
  }),
  menuCollapseIcon: css({
    // position: 'absolute',
    top: '20px',
    right: '0px',
    left: '0px',
    transform: `translateX(5%)`,
  }),
  setttingIcon: css({
    position: 'absolute',
    top: '50px',
    left: '270px',
    transform: `translateX(50%)`,
  }),
});

const getAnimStyles = (theme: GrafanaTheme2, animationDuration: number) => {
  const commonTransition = {
    transitionDuration: `${animationDuration}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
    },
  };

  const overlayTransition = {
    ...commonTransition,
    transitionProperty: 'background-color, box-shadow, width',
    // this is needed to prevent a horizontal scrollbar during the animation on firefox
    '.scrollbar-view': {
      overflow: 'hidden !important',
    },
  };

  const backdropTransition = {
    ...commonTransition,
    transitionProperty: 'opacity',
  };

  const overlayOpen = {
    backgroundColor: theme.colors.background.primary,
    boxShadow: theme.shadows.z3,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: MENU_WIDTH,
    },
  };

  const overlayClosed = {
    boxShadow: 'none',
    width: 0,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.colors.background.primary,
      width: theme.spacing(7),
    },
  };

  const backdropOpen = {
    opacity: 1,
  };

  const backdropClosed = {
    opacity: 0,
  };

  return {
    backdrop: {
      appear: css(backdropClosed),
      appearActive: css(backdropTransition, backdropOpen),
      appearDone: css(backdropOpen),
      exit: css(backdropOpen),
      exitActive: css(backdropTransition, backdropClosed),
    },
    overlay: {
      appear: css(overlayClosed),
      appearActive: css(overlayTransition, overlayOpen),
      appearDone: css(overlayOpen),
      exit: css(overlayOpen),
      exitActive: css(overlayTransition, overlayClosed),
    },
  };
};

export function NavItem({
  link,
  activeItem,
  onClose,
}: {
  link: NavModelItem;
  activeItem?: NavModelItem;
  onClose: () => void;
}) {
  const { i18n } = useLingui();
  const styles = useStyles2(getNavItemStyles);

  if (linkHasChildren(link)) {
    return (
      <CollapsibleNavItem onClose={onClose} link={link} isActive={isMatchOrChildMatch(link, activeItem)}>
        <ul className={styles.children}>
          {link.children.map(
            (childLink) =>
              !childLink.divider && (
                <NavBarMenuItem
                  key={`${link.text}-${childLink.text}`}
                  isActive={activeItem === childLink}
                  isDivider={childLink.divider}
                  icon={childLink.showIconInNavbar ? (childLink.icon as IconName) : undefined}
                  onClick={() => {
                    childLink.onClick?.();
                    onClose();
                  }}
                  styleOverrides={styles.item}
                  target={childLink.target}
                  text={childLink.text}
                  url={childLink.url}
                  isMobile={true}
                />
              )
          )}
        </ul>
      </CollapsibleNavItem>
    );
  } else if (link.emptyMessageId) {
    const emptyMessageTranslated = i18n._(menuItemTranslations[link.emptyMessageId]);
    return (
      <CollapsibleNavItem onClose={onClose} link={link} isActive={isMatchOrChildMatch(link, activeItem)}>
        <ul className={styles.children}>
          <div className={styles.emptyMessage}>{emptyMessageTranslated}</div>
        </ul>
      </CollapsibleNavItem>
    );
  } else {
    const FeatureHighlightWrapper = link.highlightText ? NavFeatureHighlight : React.Fragment;
    return (
      <li className={styles.flex} style={{ marginBottom: '18px' }}>
        <NavBarItemWithoutMenu
          className={styles.itemWithoutMenu}
          elClassName={styles.fullWidth}
          label={link.text}
          url={link.url}
          target={link.target}
          onClick={() => {
            link.onClick?.();
            onClose();
          }}
          isActive={link === activeItem}
        >
          <div className={styles.itemWithoutMenuContent} style={{ padding: '0 0 15px 0' }}>
            <div className={styles.iconContainer}>
              <FeatureHighlightWrapper>{getLinkIcon(link)}</FeatureHighlightWrapper>
            </div>
            <span className={styles.linkText}>{link.text}</span>
          </div>
        </NavBarItemWithoutMenu>
      </li>
    );
  }
}

const getNavItemStyles = (theme: GrafanaTheme2) => ({
  whiteText: css({
    color: '#FFFFFF',
  }),
  children: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  item: css({
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    width: `calc(100% - ${theme.spacing(3)})`,
    '&::before': {
      display: 'none',
    },
  }),
  flex: css({
    display: 'flex',
  }),
  itemWithoutMenu: css({
    position: 'relative',
    placeItems: 'inherit',
    justifyContent: 'start',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  }),
  fullWidth: css({
    height: '100%',
    width: '100%',
  }),
  iconContainer: css({
    display: 'flex',
    placeContent: 'center',
  }),
  itemWithoutMenuContent: css({
    display: 'grid',

    gridAutoFlow: 'column',
    gridTemplateColumns: `${theme.spacing(7)} auto`,
    alignItems: 'center',
    height: '100%',
  }),
  linkText: css({
    fontSize: theme.typography.pxToRem(14),
    justifySelf: 'start',
    padding: theme.spacing(0.5, 4.25, 0.5, 0.5),
  }),
  emptyMessage: css({
    color: theme.colors.text.secondary,
    fontStyle: 'italic',
    padding: theme.spacing(1, 1.5),
  }),
});

function CollapsibleNavItem({
  link,
  isActive,
  children,
  className,
  onClose,
}: {
  link: NavModelItem;
  isActive?: boolean;
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
}) {
  const styles = useStyles2(getCollapsibleStyles);
  const [sectionExpanded, setSectionExpanded] = useLocalStorage(`grafana.navigation.expanded[${link.text}]`, false);
  const FeatureHighlightWrapper = link.highlightText ? NavFeatureHighlight : React.Fragment;

  return (
    <li className={cx(styles.menuItem, className)} style={{ marginBottom: '18px' }}>
      <NavBarItemWithoutMenu
        isActive={isActive}
        label={link.text}
        url={link.url}
        target={link.target}
        onClick={() => {
          link.onClick?.();
          onClose();
        }}
        className={styles.collapsibleMenuItem}
        elClassName={styles.collapsibleIcon}
      >
        <FeatureHighlightWrapper>{getLinkIcon(link)}</FeatureHighlightWrapper>
      </NavBarItemWithoutMenu>
      <div className={styles.collapsibleSectionWrapper}>
        <CollapsableSection
          isOpen={Boolean(sectionExpanded)}
          onToggle={(isOpen) => setSectionExpanded(isOpen)}
          className={styles.collapseWrapper}
          contentClassName={styles.collapseContent}
          label={
            <div className={cx(styles.labelWrapper, { [styles.primary]: isActive })}>
              <span className={styles.linkText}>{link.text}</span>
            </div>
          }
        >
          {children}
        </CollapsableSection>
      </div>
    </li>
  );
}

const getCollapsibleStyles = (theme: GrafanaTheme2) => ({
  menuItem: css({
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'column',
    // marginBottom: '18px',
    gridTemplateColumns: `${theme.spacing(7)} minmax(calc(${MENU_WIDTH} - ${theme.spacing(7)}), auto)`,
  }),
  collapsibleMenuItem: css({
    height: theme.spacing(6),
    width: theme.spacing(7),
    display: 'grid',
  }),
  collapsibleIcon: css({
    display: 'grid',
    placeContent: 'center',
  }),
  collapsibleSectionWrapper: css({
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'start',
    flexDirection: 'column',
  }),
  collapseWrapper: css({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(4.25),
    minHeight: theme.spacing(6),
    overflowWrap: 'anywhere',
    alignItems: 'center',
    color: theme.colors.text.primary,
    '&:hover, &:focus-within': {
      backgroundColor: theme.colors.action.hover,
      color: theme.colors.text.primary,
    },
    '&:focus-within': {
      boxShadow: 'none',
      outline: `2px solid ${theme.colors.primary.main}`,
      outlineOffset: '-2px',
      transition: 'none',
    },
  }),
  collapseContent: css({
    padding: 0,
  }),
  labelWrapper: css({
    fontSize: '15px',
  }),
  primary: css({
    color: theme.colors.text.primary,
  }),
  linkText: css({
    fontSize: theme.typography.pxToRem(14),
    justifySelf: 'start',
  }),
});

function linkHasChildren(link: NavModelItem): link is NavModelItem & { children: NavModelItem[] } {
  return Boolean(link.children && link.children.length > 0);
}

function getLinkIcon(link: NavModelItem) {
  if (link.icon === 'grafana') {
    return <Branding.MenuLogo />;
  } else if (link.icon) {
    return <Icon name={link.icon as IconName} size="xl" />;
  } else {
    return <img src={link.img} alt={`${link.text} logo`} height="24" width="24" style={{ borderRadius: '50%' }} />;
  }
}
