import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { } from '@grafana/data';
import { AuditLogsState, AuditLog } from 'app/types';

export const initialState: AuditLogsState = {
    auditLogs: [],
    newAuditLog: {
        id: 0,
        path: "",
        name_function: "",
        user_id: 0,
        org_id: 0,
        content: "",
        created_at: new Date(),
    },
    auditLogCreate: {
        id: 0,
        path: "",
        name_function: "",
        user_id: 0,
        org_id: 0,
        content: "",
        created_at: new Date(),
    },
    hasFetched: false,
    userRole: '',
    hasUserRoleFetched: false,
    userLists: [],
    searchQuery: '',
    searchPage: 1,
    timeFilter: {
        name: 'timefilter',
        label: '',
        second: 0,
        minute: 0,
        hour: 24,  // default option
        day: 0,
        month: 0,
        year: 0,
        range: false,
        start:  new Date(),
        end:  new Date()
    },
    otherFilter: {
        name: 'otherfilter',
        label: '',
        path: [],
        name_function: [],
        user_id: [],
        event: [],
        show_get: false,
    },
    pageLimit: 10,
    otherFilterSelector: [],
    hasFilterFetched: false,
};
  
  const auditLogsSlice = createSlice({
    name: 'auditlogs',
    initialState: initialState,
    reducers: {
        auditsLoaded: (state, action): AuditLogsState => {
            return { ...state, hasFetched: true, auditLogs: action.payload };
        },
        auditsCreate: (state, action: PayloadAction<AuditLog>): AuditLogsState => {
            return { ...state, auditLogs: [...state.auditLogs, action.payload] };
        },
        userRole:(state, action): AuditLogsState  => {
            return { ...state, userRole: action.payload, hasUserRoleFetched: true };
        },
        usersLoadbyOrgId: (state, action): AuditLogsState => {
            return { ...state, userLists: action.payload };
        },
        setSearchQuery: (state, action): AuditLogsState => {
            return { ...state, searchQuery: action.payload };
        },
        setSearchPage: (state, action: PayloadAction<number>): AuditLogsState => {
            return { ...state, searchPage: action.payload };
        },
        setAuditTrailTimeFilter: (state, action): AuditLogsState => {
            return { ...state, timeFilter: action.payload };
        },
        setAuditTrailOtherFilter: (state, action): AuditLogsState => {
            return { ...state, otherFilter: action.payload };
        },
        setPageLimit:(state, action): AuditLogsState => {
            return { ...state, pageLimit: action.payload };
        },
        setOtherFilterSelector:(state, action): AuditLogsState => {
            return  { ...state, otherFilterSelector: action.payload, hasFilterFetched: true }
        }
    },
  });
  
  export const {
    auditsLoaded,
    auditsCreate,
    userRole,
    usersLoadbyOrgId,
    setSearchQuery,
    setSearchPage,
    setAuditTrailTimeFilter,
    setAuditTrailOtherFilter,
    setPageLimit,
    setOtherFilterSelector,
  } = auditLogsSlice.actions;
  
  export const auditLogsReducer = auditLogsSlice.reducer;
  
  export default {
    auditLogs: auditLogsReducer,
  };
